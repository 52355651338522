<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <div @click="$oucy.back()" class="m-b-20 pointer">&lt;返回</div>
            <!-- 0在线充值/1提现/2企业收入/3手动干预增加 /4手动干预减少/ 5保证金退回 / 6 佣金提现到余额 -->
            <el-button-group>
                <el-button :type="type==0?'primary':''" @click="setType(0)" size="mini">账单明细</el-button>
                <el-button :type="type==1?'primary':''" @click="setType(1)" size="mini">充值记录</el-button>
                <el-button :type="type==2?'primary':''" @click="setType(2)" size="mini">提现记录</el-button>
                <el-button :type="type==3?'primary':''" @click="setType(3)" size="mini">交易收入</el-button>
                <el-button :type="type==6?'primary':''" @click="setType(6)" size="mini">佣金提现到余额</el-button>
            </el-button-group>
            <el-table :data="prlductData.content" style="width: 100%" class="m-t-10" v-if="type==0||type==3||type==6">
                <el-table-column prop="date" label="操作" width="150px">
                    <!-- 0 在线充值 1用户提现 2企业商城商品(家具,材料)成交的收入 -->
                    <div slot-scope="scope" :class="{'c-m':scope.row.balanceChangeFee>0}">
                        <span v-if="scope.row.balanceChangeType===0">
                            <span v-if="scope.row.rechargePayMode===0" class="payimg">
                                <img src="@/assets/images/stockCar/WeChat.png" style="margin-right: 5px">在线充值</span>
                            <span v-else class="payimg">
                                <img src="@/assets/images/stockCar/Alipay.png" style="margin-right: 5px">在线充值</span>
                        </span>
                        <span v-if="scope.row.balanceChangeType===1"><img src="@/assets/images/stockCar/WeChat.png" style="margin-right: 5px">用户提现</span>
                        <span v-if="scope.row.balanceChangeType===2">交易收入</span>
                        <span v-if="scope.row.balanceChangeType===6">佣金提现到余额</span>
                        <!-- <span v-if="scope.row.balanceChangeType===2">企业收入</span> -->
                        <span v-if="scope.row.balanceChangeType===3">手动增加</span>
                        <span v-if="scope.row.balanceChangeType===4">手动减少</span>
                        <span v-if="scope.row.balanceChangeType===5">保证金退回</span>
                    </div>
                </el-table-column>
                <el-table-column prop="balanceChangeDetail" label="流水信息"></el-table-column>
                <el-table-column prop="address" label="金额" width="120px">
                    <div slot-scope="scope">
                        <span v-if="scope.row.balanceChangeType===1 || scope.row.balanceChangeType===4" class="">-{{scope.row.balanceChangeFee}}</span>
                        <span v-else class="c-m">+{{scope.row.balanceChangeFee}}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="id" label="订单号"></el-table-column>
                <el-table-column prop="balanceChangeCreatetime" label="时间"></el-table-column>
            </el-table>
            <el-table :data="prlductData.content" style="width: 100%" class="m-t-10" v-if="type==1">
                <el-table-column prop="" label="操作描述" width="150px">
                    <div slot-scope="scope" class="c-m">充值</div>
                </el-table-column>
                <el-table-column prop="address" label="支付渠道" width="120px">
                    <div slot-scope="scope">
                        <!-- orderPayChanal  支付渠道 0微信支付 1支付宝 -->
                        <span v-if="scope.row.orderPayChanal===0" class="payimg">
                            <img src="@/assets/images/stockCar/WeChat.png" style="margin-right: 5px">微信支付
                        </span>
                        <span v-else class="payimg">
                            <img src="@/assets/images/stockCar/Alipay.png" style="margin-right: 5px">支付宝</span>
                    </div>
                </el-table-column>
                <el-table-column prop="orderTradeNo" label="支付订单号"></el-table-column>
                <el-table-column prop="address" label="提现金额" width="120px">
                    <div slot-scope="scope">
                        <span class="c-m">￥{{scope.row.orderPayFee}}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="orderCreatetime" label="时间"></el-table-column>
            </el-table>
            <el-table :data="prlductData.content" style="width: 100%" class="m-t-10" v-if="type==2">
                <el-table-column prop="" label="操作描述" width="150px">
                    <div slot-scope="scope">提现</div>
                </el-table-column>
                <el-table-column prop="withdrawCreatetime" label="申请时间"></el-table-column>
                <el-table-column prop="address" label="提现状态" width="120px">
                    <div slot-scope="scope">
                        <!-- 提现状态 1申请中，待审核 2审核通过，待打款 3打款中 4已到账 101审核驳回 401 打款失败 -->
                        <span v-if="scope.row.withdrawStatus==1" style="color: #2090FF;">待审核</span>
                        <span v-if="scope.row.withdrawStatus==2" style="color:#6AAD42">审核通过</span>
                        <span v-if="scope.row.withdrawStatus==3">打款中</span>
                        <span v-if="scope.row.withdrawStatus==4" style="color:#555C68">已到账</span>
                        <span v-if="scope.row.withdrawStatus==101" style="color:#E6A23C">审核驳回</span>
                        <span v-if="scope.row.withdrawStatus==401" style="color:#F56C6C">打款失败</span>
                    </div>
                </el-table-column>
                <el-table-column prop="address" label="提现金额" width="120px">
                    <div slot-scope="scope">
                        <span>￥{{scope.row.withdrawFee}}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="withdrawGettime" label="到账时间"></el-table-column>
            </el-table>
            <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { balance } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            type: 0,
            prlductData: {},
            limit: 20,
            start: 0,
        }
    },
    mounted() {
        if(this.$route.query.type){
            this.type=this.$route.query.type
            this.ajax(this.type)
        }else{
            this.getBalanceHis()
        }
    },
    methods: {
        setType(v) {
            if (this.type == v) return
            this.type = v
            this.prlductData = {}
            this.limit = 20
            this.start = 0
            this.ajax(v)

        },
        ajax(v){
            if (v == 0) {
                this.getBalanceHis()
            } else
            if (v == 1) {
                this.getBalanceRecharge()
            } else if (v == 2) {
                this.getBalanceWithdraw()
            } else if (v == 3) {
                this.getBalanceHis3()
            } if (v == 6) {
                this.getBalanceHis6()
            }
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.getBalanceHis();
            this.ajax(this.type)
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.getBalanceHis();
            this.ajax(this.type)
        },
        // 账单明细
        getBalanceHis() {
            balance.getBalanceHis({
                limit: this.limit,
                start: this.start,
            }).then(res => {
                this.prlductData = res
            })
        },
        // 交易收入
        getBalanceHis3() {
            balance.getBalanceHis({
                limit: this.limit,
                start: this.start,
                balanceChangeType:2,
            }).then(res => {
                this.prlductData = res
            })
        },        
        // 佣金提现到余额
        getBalanceHis6() {
            balance.getBalanceHis({
                limit: this.limit,
                start: this.start,
                balanceChangeType:6,
            }).then(res => {
                this.prlductData = res
            })
        },
        // 查询用户在线充值历史记录
        getBalanceRecharge() {
            balance.getBalanceRecharge({
                limit: this.limit,
                start: this.start,
                orderBalancePayStatus:1,
            }).then(res => {
                this.prlductData = res
            })
        },
        // 查询用户的提现记录
        getBalanceWithdraw() {
            balance.getBalanceWithdraw({
                limit: this.limit,
                start: this.start,
            }).then(res => {
                this.prlductData = res
            })
        }
    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}
</style>